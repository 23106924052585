
class Environment {
    getBaseUrl () {
        return window.location.origin;
    }

    getHref () {
        return window.location.href;
    }

    get () {
        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
            return 'dev'
        } else if (window.location.hostname === 'app-stage.sleepcycle.com') {
            return 'test'
        }
        return 'prod'
    }

    get isChina () {
        return window.location.hostname === 'ch-app.sleepcycle.com'
    }

    isDev () {
        return this.get() === "dev"
    }

    isTest () {
        return this.get() === "test"
    }

    isProd () {
        return this.get() === "prod"
    }
}

const _Environment = new Environment();

export default _Environment;
