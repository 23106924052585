import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.scss'

const Web = lazy(() => import('./entrypoints/Web'));
const Admin = lazy(() => import('./entrypoints/Admin'));
const PartnerAdmin = lazy(() => import('./entrypoints/PartnerAdmin'));

const App = () => (
	<Router>
		<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				<Route path="/admin" component={Admin}/>
				<Route path="/partner-admin" component={PartnerAdmin}/>

				<Route path="/" component={Web}/>
			</Switch>
		</Suspense>
	</Router>
);

export default App;
