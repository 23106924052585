import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Environment from 'utils/Environment.js';


const _DEBUG_LOGGING = false;
let _LOGGED_MISSING_KEYS = {}

i18next
    .use(resourcesToBackend((language, namespace, callback) => {
        if (namespace === 'translation') {
            callback(null, [])
            return
        }

        if (_DEBUG_LOGGING) console.log(`translations - importing file ${language}/${namespace}.json`)

            import(`./translations/${language}/${namespace}.json`).then((resources) => {
                callback(null, resources)
            }).catch((error) => {
                if (_DEBUG_LOGGING) console.error(`translations - failed to import file ${language}/${namespace}.json`)
                callback(error, null)
            })
    }))
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: [
            'de',
            'en',
            'es',
            'fr',
            'it',
            'ja',
            'ko',
            'pt',
            'ru',
            'sv',
            'tr',
            'zh', 'zh-CN', 'zh-SG', 'zh-HK', 'zh-MO', 'zh-TW', 'zh-Hans', 'zh-Hant', 'zh-Hans-CN', 'zh-Hant-TW'
        ],
        debug: Environment.isDev(),
        load: 'currentOnly',
        defaultNS: 'common',
        fallbackLng:  {
            'zh': ['zh-Hans', 'en'],
            'zh-CN': ['zh-Hans', 'en'],
            'zh-SG': ['zh-Hans', 'en'],
            'zh-HK': ['zh-Hant', 'en'],
            'zh-MO': ['zh-Hant', 'en'],
            'zh-TW': ['zh-Hant', 'en'],
            'zh-Hans-CN': ['zh-Hans', 'en'],
            'zh-Hant-TW': ['zh-Hant', 'en'],
            'default': ['en']
        },
        interpolation: { escapeValue: false },  // React already safes from xss

        // The next two configurations will enable displaying error messages for missing translation keys on dev environment.
        saveMissing: Environment.isDev(),
        missingKeyHandler: (lngs, ns, key, fallbackValue, updateMissing, options) => {
            const fullKey = ns + ':' + key
            if (!_LOGGED_MISSING_KEYS[fullKey]) {
                console.info('%ctranslations - missing key ' + fullKey + ' for ' + lngs.join(', '), 'color: red')
                _LOGGED_MISSING_KEYS[fullKey] = true
            }
        }
    }
);

export default i18next;
